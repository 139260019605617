import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../App.css";
import CarouselSwiper from "../../components/carouselSwiper/carouselSwiper";
import IconsSwiper from "../../components/iconsSwiper/iconsSwiper";
import PinterestImagesCategory from "../../components/pinterestImagesCategory/pinterestImagesCategory";
import ProductsCards from "../../components/productsCards/productsCards";
import ProjectsSwiperCards from "../../components/projectsSwiperCards/projectsSwiperCards";
import AboutPage from "../aboutUs/aboutUs";
import Branches from "../ourBranches/Branches";

function Homepage(props) {
  const { t } = useTranslation();
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <IconsSwiper limit={10} offset={0} /> */}

      <CarouselSwiper
        language={props.language}
        style={{ position: "relative" }}
      />
      <div className='  w-full '>
        <div className={`container_custom py-10 grid grid-cols-1`}>
          <span className='new-products-container shadow-2xl bg-white   bg-opacity-50 lg:p-4  my-0 lg:my-8 rounded-3xl'>
            <span className='section-container'>
              <div className=' col-span-12 text-center mb-3 '>
                <h1>{t("New Products")}</h1>
                <h2 className='text-lg '>
                  {t("We Added New Products For You")}
                </h2>
              </div>
              <div className='view-all-btn text-center'>
                <Link
                  to={{ pathname: `/all-products` }}
                  className=' relative p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm '
                >
                  {t("View All")}
                </Link>
              </div>
            </span>
            <ProductsCards
              language={props.language}
              limit={10}
            />
          </span>
        </div>
      </div>

      <AboutPage home={1} />
      <PinterestImagesCategory language={props.language} />
      <Branches />
      {/* <ProjectsSwiperCards
        language={props.language}
        limit={10}
      /> */}
      {/* <OffersCards /> */}
    </div>
  );
}
export default Homepage;
