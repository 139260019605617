
import { Box, ClickAwayListener } from '@mui/material';
import { Link } from 'react-router-dom';
import classes from './suggestions.module.css';

function Suggestion(props) {



    const handleClickAway = () => {
        props.setOpen(false);
    };
    return (
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: 'relative' }}>

                    {props.open && props.show ? <ul className={`${classes.suggestions}`}>
                        {props.Suggestions.map((products, i) => {
                            return <Link onClick={() => props.setShow(false)} className={`${classes.li}`
                            } key={i} to={{ pathname: `/single-page/product/${products.product_id}` }}>
                                <span className={`${classes.name} mt-auto mb-auto`}>{products.product_name}</span><img className='w-14' src={products.main_image} />
                            </Link>
                        })}
                    </ul> : null}
                </Box>
            </ClickAwayListener>
        </div>
    );
}
export default Suggestion;