import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";

function HomeCategoriesCards(props) {
  const [Categories, fetchCategories] = useState([]);

  const getCategories = () => {
    if (props.language == "Ar") {
      fetch(
        `https://omts.app/omts/web/API/api/category-ar?offset=0&limit=${props.limit}`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    } else {
      fetch(
        `https://omts.app/omts/web/API/api/category?offset=0&limit=${props.limit}`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    }
  };
  useEffect(() => {
    getCategories();
  }, [props.language]);

  const isMobile = useMediaQuery({ maxDeviceWidth: 650 });
  if (!isMobile) {
    return (
      <div className='home-category'>
        {Categories.map((categories, i) => {
          return (
            <div
              style={{ width: "350px !important" }}
              key={i}
              className='home-category-item h-80  overflow-hidden p-4    rounded-lg group '
            >
              <Link to={{ pathname: `/categories/${categories.category_id}` }}>
                <div className='w-100 position-relative h-100 overflow-hidden rounded-lg'>
                  <div className=' absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold'>
                    {categories.category_name}
                  </div>
                  <img
                    src={categories.category_image}
                    className='object-fill w-full h-full group-hover:scale-105 transition-all rounded-lg'
                  />
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div
        className={`product-cards-container ${
          props.language == "Ar" ? "lg:float-right" : "lg:float-left"
        }`}
      >
        {Categories.map((categories, i) => {
          return (
            <div className='product-cards-items'>
              <Link to={{ pathname: `/categories/${categories.category_id}` }}>
                <div
                  className='rounded-lg m-2 sm:m-3 text-white group overflow-hidden shadow-sm hover:shadow-xl pb-0 '
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(85,240,165,1) 0%, rgba(111,184,199,1) 87%)",
                  }}
                >
                  <div className=' relative cursor-pointer rounded-lg'>
                    <div className='w-full'>
                      <div className='w-full relative overflow-hidden '>
                        {/* <div className='product-card-tag absolute p-1 z-10 bg-white rounded-md text-gray-600 m-2 shadow-sm font-bold'>
                  {categories.category_name}
                  </div> */}
                        <img
                          className='product-card-img mb-2 w-full h-48 bg-center group-hover:scale-105 transition-all object-cover'
                          src={categories.category_image}
                          width='220'
                          height='220'
                          alt='YouPhone Phone 13 Pro Max 1 TB Grafit'
                        />
                      </div>
                      <div className='w-full text-center pb-2'>
                        <span
                          key={i}
                          className='product-card-description w-full text-center h-11 overflow-hidden px-1  font-bold'
                        >
                          {categories.category_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  }
}
export default HomeCategoriesCards;
