import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./carouselSwiper.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import IconsSwiper from "../iconsSwiper/iconsSwiper";

function CarouselSwiper(props) {
  const [NewProjects, fetchNewProjects] = useState([]);

  const limit = 10;

  const getNewProjects = () => {
    fetch(`https://omts.app/omts/web/API/api/projects?limit=${limit}&offset=0`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        fetchNewProjects(res);
      });
  };

  useEffect(() => {
    getNewProjects();
  }, []);

  return (
    <div className='home-swiper-container'>
      <Swiper
        // install Swiper modules
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        dir='ltr'
        style={{ position: "relative" }}
      >
        {NewProjects.map((project, i) => {
          return (
            <SwiperSlide style={{ height: "78vh" }}>
              <Link
                to={{ pathname: `/single-page/project/${project.project_id}` }}
                key={i}
              >
                <img
                  className='w-full object-fill h-100'
                  src={project.main_image}
                  height='500'
                  width='1680'
                  alt='.'
                />
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className='category-swiper-container'>
        <div className='category-swiper-items-container'>
          <IconsSwiper
            limit={10}
            offset={0}
          />
        </div>
      </div>
    </div>
  );
}
export default CarouselSwiper;
