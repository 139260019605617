import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { height } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FooterModal = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* /**the modal starts here */}
      <span
        className='footer-prv'
        onClick={handleOpen}
      >
        {props.btnText}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
          >
            {props.titleText}
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
          >
            <div style={{ height: "80vh", overflowY: "scroll" }}>
              <div
                className='mt-8'
                dangerouslySetInnerHTML={{ __html: props.modalBody }}
              ></div>
            </div>
          </Typography>
        </Box>
      </Modal>
      {/* the modal ends here */}
    </div>
  );
};

export default FooterModal;
