import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import RelatedProducts from "../../components/relatedProducts/relatedProducts";
import SlickSlider from "../../components/slickSlider/slickSlider";

function SinglePage(props) {
  const [NewItem, fetchNewItem] = useState([]);
  const { t } = useTranslation();

  let { id } = useParams();
  let { productOrProject } = useParams();
  const GetItem = () => {
    if (props.language == "Ar") {
      if (productOrProject == "product") {
        fetch(
          "https://omts.app/omts/web/API/api/product-single-page-ar?product_id=" +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            fetchNewItem(res);
          });
      } else {
        fetch(
          "https://omts.app/omts/web/API/api/project-single-page-ar?project_id=" +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            fetchNewItem(res);
          });
      }
    } else {
      if (productOrProject == "product") {
        fetch(
          "https://omts.app/omts/web/API/api/product-single-page?product_id=" +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            fetchNewItem(res);
          });
      } else {
        fetch(
          "https://omts.app/omts/web/API/api/project-single-page?project_id=" +
            id
        )
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            fetchNewItem(res);
          });
      }
    }
  };

  useEffect(() => {
    GetItem();
  }, [id, props.language]);

  return (
    <div>
      <div className=' min-h-screen py-10'>
        {NewItem.map((item, i) => {
          return (
            <div className={`container_custom `}>
              <meta
                property='og:image'
                content={item.main_image}
              />
              <div
                className=' shadow-2xl bg-white   bg-opacity-50 lg:p-4 grid grid-cols-12  lg:my-8 rounded-3xl'
                style={{ marginTop: "10%" }}
              >
                <div className=' col-span-12 lg:col-span-5 rounded-lg '>
                  <div className='w-full'>
                    {productOrProject == "product" ? (
                      <SlickSlider projectOrProduct='product' />
                    ) : (
                      <SlickSlider projectOrProduct='project' />
                    )}
                  </div>

                  <a
                    href={`https://web.whatsapp.com/send?phone=96170739393&text=https://on.natgeo.com/2zHaNup`}
                    data-action='share/whatsapp/share'
                    target='_blank'
                    className='share-icon'
                  >
                    <button
                      type='button'
                      className='btn btn-success !w-36 m-2  p-1 z-10  rounded-md text-gray-600 m-2 shadow-sm font-bold'
                    >
                      <i className='bi bi-whatsapp float-left'></i>
                      {t("For More Info")}
                    </button>
                  </a>
                </div>
                <div className='single-page-text-container col-span-12 lg:col-span-7'>
                  <div className='lg:pl-10 sm:px-2'>
                    <span>
                      <span className=' font-semibold mt-3 block'>
                        <h2
                          key={i}
                          className='float-left'
                        >
                          {productOrProject == "product"
                            ? item.product_name
                            : item.project_name}
                        </h2>
                        {productOrProject == "product" && (
                          <Link
                            to={{ pathname: `/categories/${item.category_id}` }}
                            type='button'
                            className='btn btn-success float-right rounded-md text-white shadow-sm font-bold'
                          >
                            {item.category_name}
                          </Link>
                        )}
                      </span>

                      <div
                        className='inline-block single-product-description'
                        dangerouslySetInnerHTML={{
                          __html:
                            productOrProject == "product"
                              ? item.product_description
                              : item.project_description,
                        }}
                      ></div>
                    </span>
                    <div>
                      <div
                        className='ant-divider ant-divider-horizontal'
                        role='separator'
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <span>
                {productOrProject == "product" ? (
                  <RelatedProducts
                    language={props.language}
                    product_id={item.product_id}
                    category_id={item.category_id}
                  />
                ) : null}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default SinglePage;
