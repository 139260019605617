import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HomeCategoriesCards from "../homeCategoriesCards/categoriesCards";
import { useTranslation } from "react-i18next";

function PinterestImagesCategory(props) {
  const { t } = useTranslation();

  return (
    <div>
      <div className={`container_custom mb-14 new-categories-container`}>
        <div className=' shadow-2xl bg-white   bg-opacity-50 lg:p-4  my-0 lg:my-8 rounded-3xl'>
          <div className='section-container'>
            <div className=' col-span-12 text-center mb-3 '>
              <h1>{t("New Categories")}</h1>
            </div>
            <div className='view-all-btn text-center'>
              <Link
                to={{ pathname: `/all-categories` }}
                className=' relative p-1 z-10 bg_color rounded-md text-white m-2 shadow-sm '
              >
                {t("View All")}
              </Link>
            </div>
          </div>
          <div className='row homeFirsBoxs'>
            <HomeCategoriesCards
              language={props.language}
              limit={6}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PinterestImagesCategory;
