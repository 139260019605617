function PageNotFound() {
  return (
    <div>
      <div className=' min-h-screen bg-white '>
        <h1 className='text-center '>404 Page Not Found</h1>
        <p className='text-center'>Make sure that the url is correct</p>
      </div>
    </div>
  );
}
export default PageNotFound;
