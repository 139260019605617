import { Route, Routes } from "react-router-dom";
import "../../App.css";
import Categories from "../../pages/categories/categories";
import Homepage from "../../pages/homePage/homePage";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../components/footer/footer";
import Nav2 from "./nav2";
import AllProducts from "../../pages/allProducts/allProducts";
import AllCategories from "../../pages/allCategories/allCategories";
import AllProjects from "../../pages/allProjects/allProjects";
import AboutPage from "../../pages/aboutUs/aboutUs";
import Nav3 from "./nav3";
import Search from "../../components/search/search";
import SinglePage from "../../pages/singlePage/singlePage";
import PageNotFound from "../pageNotFound/pageNotFound";
import Nav1 from "./nav1";
import { useEffect, useState } from "react";
import i18n from "../../i18n";

function MainNav() {
  // const [showNav, setShowNav] = useState(true);
  const [inputProduct, setinputProduct] = useState("");
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(window.localStorage.getItem("language"));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("language", language);
    if (language == "Ar") {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }, [language]);

  return (
    <div style={{ direction: language == "Ar" && "rtl" }}>
      <div
        style={{
          background: "rgb(2,0,36)",
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(181,255,219,1) 0%, rgba(111,184,199,1) 100%)",
          position: "fixed",
          zIndex: "100",
          width: "100%",
        }}
      >
        <div className={`container_custom bg-opacity-50`}>
          <Nav1
            language={language}
            setLanguage={setLanguage}
          />
          <Nav2
            language={language}
            setinputProduct={setinputProduct}
          />
          <Nav3 language={language} />
        </div>
      </div>

      <ScrollToTop />

      <Routes>
        <Route
          path='/*'
          element={<PageNotFound />}
        />
        <Route
          exact
          path='/'
          element={<Homepage language={language} />}
        />
        <Route
          exact
          path='/search/:input'
          element={
            <Search
              language={language}
              inputProducts={inputProduct}
            />
          }
        />
        <Route
          exact
          path='/all-products'
          element={<AllProducts language={language} />}
        />
        <Route
          exact
          path='/all-projects'
          element={<AllProjects language={language} />}
        />
        <Route
          exact
          path='/all-categories'
          element={<AllCategories language={language} />}
        />
        <Route
          exact
          path='/about-us'
          element={<AboutPage language={language} />}
        />
        <Route
          exact
          path='/categories/:id'
          element={<Categories language={language} />}
        />
        <Route
          exact
          path='/single-page/:productOrProject/:id'
          element={<SinglePage language={language} />}
        />
      </Routes>
      <Footer language={language} />
    </div>
  );
}
export default MainNav;
