// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import classes from "./iconsSwiper.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import Swiper core and required modules

function IconsSwiper(props) {
  const [Categories, fetchCategories] = useState([]);
  const getCategories = () => {
    fetch(
      `https://omts.app/omts/web/API/api/category?limit= ${props.limit} & offset=${props.offset}`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        fetchCategories(res);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className={` ${classes.background_color} pt-4 pb-4`}>
      <Swiper
        className={`container_custom ${classes.background_color}`}
        // install Swiper modules
        spaceBetween={10}
        slidesPerView={7}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
        breakpoints={{
          300: {
            slidesPerView: 2,
            spaceBetween: 5,
            slidesPerGroup: 1,
          },
          460: {
            slidesPerView: 2,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 50,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
        }}
      >
        {Categories.map((categories, i) => {
          return (
            <SwiperSlide>
              <Link
                to={{ pathname: `/categories/${categories.category_id}` }}
                key={i}
              >
                <img
                  src={categories.category_image}
                  style={{ width: "120px", height: "120px" }}
                  alt='Las Dress'
                  className='h-20 w-20 object-cover mx-auto border-brand-color border box-border rounded-full block border-solid '
                />
                <span className='text-white w-full float-left text-xs mt-2 text-center'>
                  {categories.category_name}
                </span>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
export default IconsSwiper;
