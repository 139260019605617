import { useTranslation } from "react-i18next";
import ProductsCards from "../../components/productsCards/productsCards";

function AllProducts(props) {
  const { t } = useTranslation();
  return (
    <div className='  w-full '>
      <div className={`container_custom py-10 grid grid-cols-1`}>
        <div className='mobile-mt col-span-12 text-center mb-5 mt-[11%]'>
          <h1 className='text-white'>{t("All Products")}</h1>
        </div>
        <ProductsCards
          language={props.language}
          productOrProject='products'
          allProducts='allProducts'
        />
      </div>
    </div>
  );
}
export default AllProducts;
