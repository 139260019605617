import About from "../../components/about/about";

function AboutPage(props) {
  return (
    <About
      language={props.language}
      home={props.home}
    />
  );
}
export default AboutPage;
