import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductsCards from "../productsCards/productsCards";

function RelatedProducts(props) {
  const { t } = useTranslation();
  const [related, setRelated] = useState("");
  return (
    <div className=' shadow-2xl bg-white   bg-opacity-50 lg:p-4 grid grid-cols-1 my-0 lg:my-8 rounded-3xl mt-5 related_products'>
      {related ? (
        <h2 className='w-100 text-center'>{t("Related Products")}</h2>
      ) : (
        <h2 className='w-100 text-center'>{t("No Related Products")}</h2>
      )}
      <ProductsCards
        language={props.language}
        setRelated={setRelated}
        product_id={props.product_id}
        category_id={props.category_id}
      />
    </div>
  );
}
export default RelatedProducts;
