import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";

function Nav3(props) {
  const [Categories, fetchCategories] = useState([]);
  const getData = () => {
    if (props.language == "Ar") {
      fetch("https://omts.app/omts/web/API/api/category-parents-ar?limit=10")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    } else {
      fetch("https://omts.app/omts/web/API/api/category-parents?limit=10")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [props.language]);

  $(".navigation li").hover(function () {
    var isHovered = $(this).is(":hover");
    if (isHovered) {
      $(this).children("ul").stop().slideDown(100);
    } else {
      $(this).children("ul").stop().slideUp(100);
    }
  });

  const { t } = useTranslation();
  // $(function () {
  //   $(".selectpicker").selectpicker();
  // });

  function changedLanguage() {
    var selectedLanguage = $("#selectionLanguage")
      .find("option:selected")
      .val();
    props.setLanguage(selectedLanguage);
  }
  return (
    <div className='nav-3-container'>
      <div className='dropdown inline-block relative float-left'>
        <button className=' hover:text-orange-400 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5'>
          <Link
            className='sm:text-sm text-xs fw-bold'
            to='/'
          >
            {t("Home")}
          </Link>
        </button>
        <button className=' hover:text-orange-400 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5'>
          <Link
            className='sm:text-sm text-xs fw-bold'
            to={{ pathname: `/all-categories` }}
          >
            {t("Categories")}
          </Link>
        </button>
        <button className=' hover:text-orange-400 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5'>
          <Link
            className='sm:text-sm text-xs fw-bold'
            to={{ pathname: `/all-products` }}
          >
            {t("Products")}
          </Link>
        </button>
        {/* <button className=' hover:text-orange-400 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5'>
          <Link
            className='sm:text-sm text-xs fw-bold'
            to={{ pathname: `/all-projects` }}
          >
            {t("Projects")}
          </Link>
        </button> */}
        <button className=' hover:text-orange-400 text-gray-700 font-semibold p-1 sm:p-2  rounded inline-flex items-center sm:mr-5'>
          <Link
            className='sm:text-sm text-xs fw-bold'
            to={{ pathname: `/about-us` }}
          >
            {t("About Us")}
          </Link>
        </button>
      </div>
    </div>
  );
}
export default Nav3;
