import { Link } from "react-router-dom";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef, useCallback } from "react";
import Ticker from "react-ticker";

/**for the cp ticker  */
const newsItems = [
  {
    key: "01391390 4411",
  },
  {
    key: "01391390 4405",
  },
  {
    key: "01391390 4404",
  },
  {
    key: "01391390 4405",
  },
  {
    key: "01391390 4417",
  },
  {
    key: "sales.ipick@omts.app",
  },
  {
    key: "csamra@omts.app",
  },
  {
    key: "hkalaawe@omts.app",
  },
  {
    key: "hchahine@omts.app",
  },
  {
    key: "mmetri@omts.app",
  },
];

const Element = (props) => {
  const { title } = props;

  return <div className='element'>{title}</div>;
};

function Nav1(props) {
  // const [SelectedLanguage, setSelectedLanguage] = useState('En');
  // const { t } = useTranslation();
  // $(function () {
  //   $(".selectpicker").selectpicker();
  // });

  function changedLanguage() {
    var selectedLanguage = $("#selectionLanguage")
      .find("option:selected")
      .val();
    props.setLanguage(selectedLanguage);
  }

  /**for the news ticker to check the width so that the speed wont change */
  const tickerRef = useRef();
  // useEffect(() => {
  //   const ticker = tickerRef.current;
  //   const tickerWrap = ticker.querySelector(".ticker-wrap");
  //   const tickerItems = ticker.querySelectorAll(".ticker-item");
  //   const tickerWidth = [...tickerItems].reduce((acc, item) => {
  //     return acc + item.offsetWidth;
  //   }, 0);

  //   const animationDuration = tickerWidth / 50; // 50 pixels per second

  //   tickerWrap.style.animationDuration = `${animationDuration}s`;
  // }, []);

  /**codepin ticker */
  const [items, setItems] = useState(newsItems);
  const [animationRunning, setAnimationRunning] = useState(false);
  const wrapperRef = useRef();
  const indexRef = useRef();

  useEffect(() => {
    let timerId;
    if (!animationRunning) {
      timerId = setTimeout(() => {
        setAnimationRunning(true);
      }, 3000); // change this value to set the interval between animation loops
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [animationRunning]);

  const handleRefUpdate = useCallback(
    (node) => {
      if (node !== null && items.length > 0) {
        indexRef.current = node.firstChild;
        wrapperRef.current = node;
        document.documentElement.style.setProperty(
          "--animationDistance",
          `${0 - indexRef.current.offsetWidth}px`
        );
        document.documentElement.style.setProperty(
          "--animationDuration",
          `${Math.round(indexRef.current.offsetWidth / 100)}s`
        );
        wrapperRef.current.classList.add("moving");
      }
    },
    [items]
  );

  const handleLoop = () => {
    wrapperRef.current.classList.remove("moving");
    wrapperRef.current.style.animation = "none";
    const t = wrapperRef.current.offsetHeight; /* trigger reflow */
    wrapperRef.current.style.animation = null;

    const copy = [...items]; // no need to pass items as an argument
    const firstitem = copy.shift();
    copy.splice(copy.length, 0, firstitem);
    setItems(copy);
  };

  const shiftNext = (copy) => {
    const firstitem = copy.shift();
    copy.splice(copy.length, 0, firstitem);
    setItems(copy);
  };

  const handleAnimationEnd = () => {
    handleLoop();
  };

  return (
    // <div className='news-wrapper'>
    //   <Ticker>
    //     {({ index }) => (
    //       <>
    //         {items.map((obj, i) => (
    //           <Element
    //             title={obj.key}
    //             key={obj.key + i}
    //           />
    //         ))}
    //       </>
    //     )}
    //   </Ticker>
    // </div>
    <div className='menu'>
      <ul
        className='topNav_ul__F0tE5 nav-menu '
        style={{ marginBottom: "0px" }}
      >
        <span className='phoneNb topNav_span2_ltr__pVlCu nav-menu-phone-number  bg_color'>
          <i className='bi bi-telephone-fill sm:mx-2'></i>
          <a href='tel:+961-01391000'>+961-01391000</a>
        </span>
        <li className='topNav_li_ltr__XJTHU nav-menu-welcome-text'>
          <span>Welcome To Omts</span>
        </li>
      </ul>
    </div>
  );
}
export default Nav1;
