// Import Swiper styles
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

function ProjectsCards(props) {
  const [Projects, fetchProjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(20);
  const limit = 20;
  const [isLoading, setIsLoading] = useState(true);
  const getNewProjects = () => {
    if (props.language == "Ar") {
      fetch(
        `https://omts.app/omts/web/API/api/projects-ar?limit=${limit}&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchProjects(res);
          setIsLoading(false);
        });
    } else {
      fetch(
        `https://omts.app/omts/web/API/api/projects?limit=${limit}&offset=0`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchProjects(res);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getNewProjects();
  }, [props.language]);

  //load more start
  const getRemainingProjects = async () => {
    if (props.language == "Ar") {
      const res = await fetch(
        `https://omts.app/omts/web/API/api/projects-ar?offset=${offset}&limit=20`
      );
      const data = res.json();
      return data;
    } else {
      const res = await fetch(
        `https://omts.app/omts/web/API/api/projects?offset=${offset}&limit=20`
      );
      const data = res.json();
      return data;
    }
  };
  const fetchData = async () => {
    const infiniteScroll = await getRemainingProjects();
    fetchProjects([...Projects, ...infiniteScroll]);
    if (infiniteScroll.length === 0 || infiniteScroll.length < 20) {
      setHasMore(false);
    }
    setOffset(offset + 20);
  };
  //load more end
  // function dataCheck() {
  //     if (Projects) {
  //         let classes = 'grid grid-cols-2 md:grid-cols-5 col-span-12 float-left'
  //         return classes
  //     } else {
  //         let classes = ''
  //         return classes
  //     }
  // }

  return (
    <div>
      {isLoading ? (
        <div
          className='spinner-border text-primary  relative inset-x-2/4 block'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={Projects.length} //This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          loader={
            <div
              className='spinner-border text-primary  relative inset-x-2/4 block'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          }
          className='overflow-hidden'
          // className={dataCheck()}
        >
          <div className='all-project-container'>
            {Projects.length > 0 ? (
              Projects.map((project, i) => {
                return (
                  <div
                    className={`h-80  overflow-hidden ${
                      props.language == "Ar" ? "float-right" : "float-left"
                    } rounded-lg group  w-6/12 md:w-1/5`}
                  >
                    <Link
                      to={{
                        pathname: `/single-page/project/${project.project_id}`,
                      }}
                    >
                      {/* <Link to={{ pathname: '/projectSinglePage', search: `?project_id=${project.project_id}` }}> */}
                      <div className='rounded-lg m-2 sm:m-3 bg-white group overflow-hidden shadow-sm hover:shadow-xl pb-0 '>
                        <div className=' relative cursor-pointer rounded-lg'>
                          <div className='w-full'>
                            <div className='w-full relative overflow-hidden '>
                              <img
                                className='w-full h-48 bg-center rounded-t-lg group-hover:scale-105 transition-all object-cover'
                                src={project.main_image}
                                width='220'
                                height='220'
                                alt='YouPhone Phone 13 Pro Max 1 TB Grafit'
                              />

                              <ul className='project-links'></ul>
                            </div>
                            <div className='mt-2 w-full'>
                              {/* <h3 key={i} className="w-full text-center h-11 overflow-hidden px-1 mt-2 text-black font-bold ">
                                            {project.category_name}</h3> */}
                              <div className=' text-center text-md h-12 z-10 relative '>
                                <span className='font-semibold'>
                                  <span>{project.project_name}</span>
                                </span>
                                <span className=' line-through text-xs w-full float-left '></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <h1>No Projects Found </h1>
            )}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}
export default ProjectsCards;
