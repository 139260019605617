import React, { useEffect, useState } from "react";

function About(props) {
  const [About, fetchAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const GetAbout = () => {
    if (props.language == "Ar") {
      fetch("https://omts.app/omts/web/API/api/about-ar")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchAbout(res);
          setIsLoading(false);
        });
    } else {
      fetch("https://omts.app/omts/web/API/api/about")
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchAbout(res);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    GetAbout();
  }, [props.language]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <div
          className='spinner-border text-primary  relative inset-x-2/4 block'
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      ) : (
        <div className='about-us-container'>
          <h1
            className='mobile-mt styledTitle_titleH1__iVF-P'
            style={props.home ? { marginTop: "3%  " } : { marginTop: "12%  " }}
          >
            About Us
          </h1>

          <div className='about-us-items'>
            <div className='about-us-img'>
              <img
                className='about-us-image entered loaded  object-cover'
                // src={About.file_path}
                src='./imgs/omtsLogo.png'
              />
            </div>

            <div className='about-us-text'>
              <h2
                className='about-us-text-title mb-8'
                style={{ color: "rgb(137, 165, 142)" }}
              >
                {/* {About.title} */}
                Omts
              </h2>
              {/* <p
                className='mt-8'
                dangerouslySetInnerHTML={{ __html: About.description }}
              ></p> */}
              <p className='mt-8'>
                We have been in the business for quite a while now, and in that
                time we have not only managed to make close relationships with
                all local suppliers, but also to recognize what people need.
                This means that we are always able to offer all the latest
                technologies, great prices, reliable service and premium
                customer support. OMTS gives you a chance to quickly and easily
                find the product you want and have it in no specific time.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default About;
