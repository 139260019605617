import { Dropdown } from "bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";
import Suggestion from "../suggestions/suggestions";
import since from "../../nabat_since_1983.PNG";
import { useMediaQuery } from "react-responsive";

function Nav2(props) {
  const isMobile = useMediaQuery({ maxDeviceWidth: 650 });

  const { t } = useTranslation();
  // $(function () {
  //   $(".selectpicker").selectpicker();
  // });

  function changedLanguage() {
    var selectedLanguage = $("#selectionLanguage")
      .find("option:selected")
      .val();
    props.setLanguage(selectedLanguage);
  }

  const [Logo, fetchLogo] = useState([]);
  const [Suggestions, setSuggestions] = useState([]);
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(true);
  const [inputProducts, setInputProducts] = useState("");

  const getLogo = () => {
    fetch("https://omts.app/omts/web/API/api/theme-settings")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        fetchLogo(res);
      });
  };

  useEffect(() => {
    getLogo();
  }, []);

  //Products Search start

  let inputHandlerProducts = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputProducts(lowerCase);
    if (props.language == "Ar") {
      fetch(
        `https://omts.app/omts/web/API/api/search-suggestions-products-ar?key=${lowerCase}`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setSuggestions(res);
        });
    } else {
      fetch(
        `https://omts.app/omts/web/API/api/search-suggestions-products?key=${lowerCase}`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setSuggestions(res);
        });
    }
    setShow(true);
    setOpen(true);
    props.setinputProduct(lowerCase);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      document.getElementById("search_btn").click();
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  Dropdown.handleClickOutside = () => setIsOpen(false);

  return (
    <div className='nav-2-container flex align-items-center   '>
      <div className='nav-logo-icon   mr-3   md:mr-0  md:mt-2 lg:mt-3'>
        <Link to={`/`}>
          <img
            src='./imgs/omtsLogo.png'
            width='169'
            height='44'
            className=' w-full sm:w-10/12 sm:mt-0  '
            alt='Logo'
            style={{ width: "100%", height: "100%" }}
          />
        </Link>
      </div>
      <div className=' nav-search-container'>
        <span className='ant-input-group-wrapper ant-input-search ant-input-search-middle ant-input-search-with-button sm:px-10 '>
          <span className='ant-input-wrapper ant-input-group'>
            <input
              onChange={inputHandlerProducts}
              onKeyDown={handleKeypress}
              type='text'
              placeholder={t("Search for products...")}
              className='ant-input nav-search-input'
              defaultValue={""}
              style={{ borderRadius: "10px 0 0 10px", fontSize: "1.1rem" }}
            />
            <Link
              id='search_btn'
              to={{ pathname: `/search/${inputProducts}` }}
              className='ant-input-group-addon'
              style={{ borderRadius: "0 5px 5px 0", height: "30px" }}
            >
              <button
                disabled={!inputProducts}
                type='button'
                className={`ant-btn ant-btn-primary ant-input-search-button`}
                style={{ borderRadius: "0 5px 5px 0", height: "100%" }}
              >
                <span
                  role='img'
                  aria-label='search'
                  className={`anticon anticon-search`}
                >
                  <svg
                    className={`search_color`}
                    viewBox='64 64 896 896'
                    focusable='false'
                    data-icon='search'
                    width='1em'
                    height='1em'
                    fill='currentColor'
                    aria-hidden='true'
                  >
                    <path d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'></path>
                  </svg>
                </span>
              </button>
            </Link>
          </span>
          <div>
            {
              <Suggestion
                Suggestions={Suggestions}
                inputHandlerProducts={inputHandlerProducts}
                setSuggestions={setSuggestions}
                show={show}
                setShow={setShow}
                isOpen={isOpen}
                open={open}
                setOpen={setOpen}
              />
            }
          </div>
        </span>
      </div>
      {/* <div className='flex justify-center float-left  sm:float-right'>
        <div className='mt-1 '>
          {props.language == "En" || props.language == "" ? (
            <select
              onChange={changedLanguage}
              id='selectionLanguage'
              className='form-select  mb-3 
      appearance-none
      block
      w-full
      px-4
      py-2
      sm:text-xl
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-1
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              aria-label='.form-select-lg example'
            >
              <option
                selected
                value='En'
              >
                English
              </option>
              <option value='Ar'>عربية</option>
            </select>
          ) : (
            <select
              onChange={changedLanguage}
              id='selectionLanguage'
              className='form-select mb-3 
      appearance-none
      block
      w-full
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-1
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
              aria-label='.form-select-lg example'
            >
              <option value='En'>English</option>
              <option
                selected
                value='Ar'
              >
                عربية
              </option>
            </select>
          )}
        </div>
      </div> */}

      {/* <div className='flex justify-center    float-left  sm:float-right language-select-container'>
        <div className='sm:!pt-5   flex align-items-center'>
          <i className='bi bi-globe text-white text-xl '></i>
          <select
            id='selectionLanguage'
            className='language-select-drop-down '
            aria-label='.form-select-lg example'
          >
            <option
              className='text-slate-400'
              value='En'
            >
              {isMobile ? "En" : "English"}
            </option>
            <option
              className='text-slate-400'
              value='Ar'
            >
              عربية
            </option>
          </select>
          <i className='bi bi-caret-down relative  text-white'></i>
        </div>
      </div> */}
    </div>
  );
}
export default Nav2;
