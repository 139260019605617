import { useTranslation } from "react-i18next";
import ProjectsCards from "../../components/projectsCards/projectsCards";

function AllProjects(props) {
  const { t } = useTranslation();
  return (
    <div className='  w-full '>
      <div className={`container_custom py-10 grid grid-cols-1`}>
        <div className='mobile-mt col-span-12 text-center mb-5 mt-[11%]'>
          <h1 className='text-white'>{t("All Projects")}</h1>
        </div>
        <ProjectsCards
          language={props.language}
          productOrProject='projects'
        />
      </div>
    </div>
  );
}
export default AllProjects;
