import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductsCards from "../../components/productsCards/productsCards";

function Categories(props) {
  const { id } = useParams();
  const limit = 200;
  const [Categories, fetchCategories] = useState([]);
  const getCategories = () => {
    if (props.language == "Ar") {
      fetch(
        "https://omts.app/omts/web/API/api/category-parents-ar?limit=" + limit
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    } else {
      fetch("https://omts.app/omts/web/API/api/category-parents?limit=" + limit)
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchCategories(res);
        });
    }
  };

  useEffect(() => {
    getCategories();
  }, [props.language]);
  return (
    <div className='container_custom'>
      <div
        className='mobile-mt grid grid-cols-12 h-full  py-2 bg-white   bg-opacity-50 '
        style={{ marginTop: "12%" }}
      >
        <div className='md:col-span-2 col-span-12 p-2 shadow-sm border-top md:relative md:top-auto md:right-auto md:left-auto md:bottom-auto md:visible md:block sm:invisible xs:invisible hidden '>
          <div className='categoriesFilter w-full float-left  max-h-96 overflow-y-auto border pl-1 rounded-bottom bg-transparent'>
            <div
              role='tree'
              className='ant-tree ant-tree-icon-hide bg-transparent'
            >
              <div
                className='ant-tree-list'
                style={{ position: "relative" }}
              >
                <div className='ant-tree-list-holder'>
                  <div>
                    <div
                      className='ant-tree-list-holder-inner'
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {Categories.map((categories, i) => {
                        return (
                          <div key={i}>
                            <div
                              className='ant-tree-treenode  ant-tree-treenode-switcher-open'
                              draggable='false'
                              aria-grabbed='false'
                            >
                              <span
                                title={categories.category_name}
                                className='ant-tree-node-content-wrapper ant-tree-node-content-wrapper-open fw-bold'
                              >
                                <Link
                                  key={i}
                                  to={{
                                    pathname: `/categories/${categories.category_id}`,
                                  }}
                                  category_type='parent'
                                >
                                  <span className='ant-tree-title'>
                                    {categories.category_name}
                                  </span>
                                </Link>
                              </span>
                            </div>
                            {categories.childs.map((subcategories, j) => {
                              return (
                                <div
                                  className='ant-tree-treenode ant-tree-treenode-switcher-close'
                                  draggable='false'
                                  aria-grabbed='false'
                                >
                                  <span
                                    title={subcategories.category_name}
                                    className='lg:!ml-5 ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal'
                                  >
                                    <Link
                                      key={i}
                                      to={{
                                        pathname: `/categories/${subcategories.category_id}`,
                                      }}
                                      category_type='child'
                                    >
                                      <span className='ant-tree-title'>
                                        {subcategories.category_name}
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' md:col-span-10 col-span-12 '>
          <div className='w-full mt-3 float-left'>
            <div className='container '>
              <ProductsCards
                language={props.language}
                filter_categories={id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Categories;
