import { useTranslation } from "react-i18next";
import CategoriesCards from "../../components/categoriesCards/categoriesCards";

function AllCategories(props) {
  const { t } = useTranslation();

  return (
    <div className={` `}>
      <div className={`container_custom mb-14`}>
        <div className='mobile-mt table mt-[11%]'>
          <div className=' col-span-12 text-center pt-10 '>
            <h1 className='text-white'>{t("All Categories")}</h1>
          </div>
        </div>
        <div className='categories-page-container'>
          <CategoriesCards language={props.language} />
        </div>
      </div>
    </div>
  );
}
export default AllCategories;
