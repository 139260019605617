import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";

function SlickSlider(props) {
  const [NewProducts, fetchNewProducts] = useState([]);

  let { id } = useParams();
  const GetNewProducts = () => {
    if (props.projectOrProduct == "product") {
      fetch(
        "https://omts.app/omts/web/API/api/product-single-page?product_id=" + id
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchNewProducts(res);
        });
    } else {
      fetch(
        "https://omts.app/omts/web/API/api/project-single-page?project_id=" + id
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          fetchNewProducts(res);
        });
    }
  };
  useEffect(() => {
    GetNewProducts();
  }, [id]);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <div>
      {NewProducts.map((product) => {
        return (
          <div>
            <Slider
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              arrows={false}
            >
              <div className='item -mb-10'>
                <img
                  src={product.main_image}
                  width='500'
                  height='500'
                  alt=' '
                  className='w-full'
                  style={{ height: "400px" }}
                />
              </div>
              {product.images.map(function (additional_images, i) {
                return (
                  additional_images.file_path && (
                    <div className='item -mb-10'>
                      <img
                        key={i}
                        src={additional_images.file_path}
                        width='500'
                        height='500'
                        alt=' '
                        className='w-full'
                        style={{ height: "400px" }}
                      />
                    </div>
                  )
                );
              })}
            </Slider>
            {product.number_additional_images.map(function (
              number_additional_images,
              i
            ) {
              var images_number = parseInt(
                number_additional_images.number_of_images
              );
              if (images_number == 1) {
                images_number = 2;
              }
              return (
                number_additional_images.number_of_images != "0" && (
                  <Slider
                    key={i}
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    slidesToShow={images_number}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    arrows={false}
                  >
                    <div className='item'>
                      <div
                        className='bg-cover bg-center border-2 ml-2 h-20 '
                        style={{
                          backgroundImage: `url(${product.main_image}) `,
                        }}
                      ></div>
                    </div>

                    {product.images.map(function (additional_images, i) {
                      return (
                        additional_images.file_path && (
                          <div
                            className='item'
                            key={i}
                          >
                            <div
                              className='bg-cover bg-center border-2 ml-2 h-20 '
                              style={{
                                backgroundImage: `url(${additional_images.file_path})`,
                              }}
                            ></div>
                          </div>
                        )
                      );
                    })}
                  </Slider>
                )
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
export default SlickSlider;
