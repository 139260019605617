import React, { useEffect, useState } from "react";

function Branches(props) {
  const [About, fetchAbout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const GetAbout = () => {
  //   if (props.language == "Ar") {
  //     fetch("https://omts.app/omts/web/API/api/about-ar")
  //       .then((res) => res.json())
  //       .then((res) => {
  //         console.log(res);
  //         fetchAbout(res);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     fetch("https://omts.app/omts/web/API/api/about")
  //       .then((res) => res.json())
  //       .then((res) => {
  //         console.log(res);
  //         fetchAbout(res);
  //         setIsLoading(false);
  //       });
  //   }
  // };
  // useEffect(() => {
  //   GetAbout();
  // }, [props.language]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        <div
          class='spinner-border text-primary  relative inset-x-2/4 block'
          role='status'
        >
          <span class='visually-hidden'>Loading...</span>
        </div>
      ) : (
        <div
          class='our-branches-container'
          style={{
            background: `url(./imgs/map-bg-1.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionX: "50%",
          }}
        >
          <h1
            class='styledTitle_titleH1__iVF-P'
            style={{ marginTop: "20px" }}
          >
            Our Branches
          </h1>
          <div class='branches-items-container'>
            <div className='branch-item'>
              <iframe
                class='mb-10 shadow-2xl'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.59482748884!2d35.5185229!3d33.874331399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17afa16e315b%3A0x3fd6f2ddb85875f9!2sOMTS!5e0!3m2!1sen!2slb!4v1680109880718!5m2!1sen!2slb'
                width='450'
                height='450'
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
              <h2
                className='text-center'
                style={{ color: " rgb(137, 165, 142)" }}
              >
                OMTS Headquarters
              </h2>
              <p class='font-medium mb-5'>VGF9+PCJ, Sami El Solh, Beirut</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Branches;
