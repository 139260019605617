import ProductsCards from "../productsCards/productsCards";

function Search(props) {
  return (
    <div>
      {/* <InfiniteScroll
                dataLength={props.Products.length} //This is important field to render the next data
                next={props.fetchData}
                hasMore={props.hasMore > 0}
                loader={props.Products.length == 0 ? <p>No data found</p> : <div className="spinner-border text-primary  relative inset-x-2/4 block" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>}
            > */}
      <div className=' w-full '>
        <div className={`container_custom py-10 grid grid-cols-1`}>
          <ProductsCards
            language={props.language}
            inputProducts={props.inputProducts}
          />
        </div>
      </div>
      {/* </InfiniteScroll> */}
    </div>
  );
}
export default Search;
